/* eslint-disable */
import { autorun, makeAutoObservable, runInAction } from "mobx";
import { task } from "mobx-fetch-task";
import { store } from "hooks/useStore";
import TableListStore from "../SupportingInformation/MnemoschemeModal/components/TableList/tableListStore";
import { toObjectStyles } from "../SupportingInformation/MnemoschemeModal/components/TableList/components/EditStyleModal/common";
import request from "utils/request";

class MnemoStore {

  selectedTemplateId = undefined;
  templates = [];

  tableListStore = undefined;
  wellId = undefined;

  constructor() {
    makeAutoObservable(this, {
      fetchAvailableParameters: false,
      fetchTemplateList: false,
      fetchTemplateTables: false
    });

    autorun(() => {
      const wellId = store.wellStore?.id;
      if (wellId && this.wellId !== wellId) {
        this.selectWell(wellId);
      }
    });
  }

  fetchAvailableParameters = task(async () => {
    const { data } = await request.get("/settings/trends/get/paramsList");
    return data;
  });

  fetchTemplateList = task(async () => {
    const { data: templates } = await request.get("/mnemoTemplates");
    this.templates = templates;
  });

  fetchTemplateTables = task(async (templateId) => {
    if (!this.wellId) return;

    try {
      const { data: tablesDTO } = await request
        .get(`/mnemoTemplates/${templateId}/tables`);

      if (!this.fetchAvailableParameters.resolved) {
        await this.fetchAvailableParameters();
      }

      const availableParameters = this.fetchAvailableParameters.result ?? [];

      const tables = tablesDTO.map(table => ({
        id: table.id,
        name: table.name,
        parameters: table.parameters,
        position: { x: table.positionX, y: table.positionY },
        styles: toObjectStyles(table.styles)
      }));

      if (this.tableListStore?.socket?.socket) {
        this.tableListStore?.socket?.socket.close()
      }
      this.tableListStore = new TableListStore(
        this.selectedTemplateId,
        availableParameters,
        this.wellId
      );

      this.tableListStore.setTables(tables);
      this.tableListStore.subscribeRealtime();
    } catch (error) {
      console.log(error);
      if (this.tableListStore?.socket?.socket) {
        this.tableListStore?.socket?.socket.close()
      }
      this.tableListStore = undefined;
      throw error;
    }
  });

  selectTemplateAndSave(id) {
    this.selectTemplate(id);
    this.saveChosenTemplate(this.wellId);
  }

  selectTemplate(id) {
    this.tableListStore?.unsubscribeRealtime();

    runInAction(() => {
      this.selectedTemplateId = id;
      if (id) {
        this.fetchTemplateTables(this.selectedTemplateId);
      } else {
        if (this.tableListStore?.socket?.socket) {
          this.tableListStore?.socket?.socket.close()
        }
        this.tableListStore = undefined;
      }
    });
  }

  selectWell(id) {
    this.wellId = id;
    this.loadChosenTemplate(this.wellId);
  }

  get selectedTemplate() {
    return this.templates
      .find(tmpl => tmpl.id === this.selectedTemplateId);
  }

  async createTemplate(name) {
    const { data: id } = await request
      .post("/mnemoTemplates", { name });

    const template = {
      id,
      name,
      isDefault: false
    }

    this.templates.push(template);

    return template;
  }

  async deleteSelectedTemplate() {
    await request
      .delete(`/mnemoTemplates/${this.selectedTemplateId}`);

    runInAction(() => {
      this.templates = this.templates
        .filter(tmpl => tmpl.id !== this.selectedTemplateId);

      this.selectTemplate(undefined);
      if (this.tableListStore?.socket?.socket) {
        this.tableListStore?.socket?.socket.close()
      }
      this.tableListStore = undefined;
    });
  }

  async renameSelectedTemplate(name) {
    await request.put(`/mnemoTemplates/name`, {
      id: this.selectedTemplateId,
      name
    });

    this.selectedTemplate.name = name;
  }

  async saveChosenTemplate(wellId) {
    await request
      .post("/mnemoTemplates/relation/wells", {
        wellId,
        templateId: this.selectedTemplateId
      });
  }

  async loadChosenTemplate(wellId) {
    try {
      const { data: template } = await request
        .get(`/mnemoTemplates/byWellId/${wellId}`);
      this.selectTemplate(template?.id);
    } catch (error) {
      this.selectTemplate(undefined);
    }
  }
}

export default MnemoStore;
